import axios from 'axios';

// Dynamically set the base URL based on the environment
const baseURL = import.meta.env.PROD
  ? 'https://masternmaster-backend.onrender.com'
  : 'http://localhost:5009';

// Create an Axios instance with the configured base URL and headers
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;