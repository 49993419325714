import React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from '../axiosConfig';
import html2pdf from 'html2pdf.js';

// Define FabricOrder and FabricCompany types based on your types/index.ts
interface FabricOrder {
    dealerCode: string;
    orderNumber: string;
    fabricCompany: string;
    fabricNumber: string;
    yardsRequired: string;
    metersRequired: number;
    deliveryAddress: string;
    dealerCodeError?: string;  // Note: no double "Error"
    orderNumberError: string | undefined;
    fabricCompanyError: string | undefined;
    fabricNumberError: string | undefined;
    yardsRequiredError: string | undefined;
    metersRequiredError: string | undefined;
    deliveryAddressError: string | undefined;
}

interface FabricCompany {
    supplierCode: string;
    // Add other properties of FabricCompany if needed
}

interface SuggestionState {
    rowIndex: number | null;
    field: keyof FabricOrder | null;
}

const OrderFabricButton: React.FC = () => {
    const [rows, setRows] = useState<FabricOrder[]>([
        {
            dealerCode: '',
            orderNumber: '',
            fabricCompany: '',
            fabricNumber: '',
            yardsRequired: '',
            metersRequired: 0,
            deliveryAddress: '',
            dealerCodeError: undefined,
            orderNumberError: undefined,
            fabricCompanyError: undefined,
            fabricNumberError: undefined,
            yardsRequiredError: undefined,
            metersRequiredError: undefined,
            deliveryAddressError: undefined,
        },
    ]);
    const [dealerCodes, setDealerCodes] = useState<string[]>([]);
    const [fabricCompanies, setFabricCompanies] = useState<FabricCompany[]>([]);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [tailorCodes, setTailorCodes] = useState<string[]>([]);
    const [activeSuggestion, setActiveSuggestion] = useState<SuggestionState>({
        rowIndex: null,
        field: null,
    });
    const tableRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const [dealersResponse, fabricCompaniesResponse, tailorsResponse] = await Promise.all([
                    axios.get('/api/dealers/codes'),
                    axios.get('/api/fabrics/fabric-companies'),
                    axios.get('/api/tailors/codes'),
                ]);
                setDealerCodes(dealersResponse.data.codes || []);
                setFabricCompanies(fabricCompaniesResponse.data.fabricCompanies || []);
                setTailorCodes(tailorsResponse.data.tailorCodes || []);
            } catch (err) {
                const error = err as Error;
                console.error('Failed to fetch initial data:', error);
            }
        };
        fetchInitialData();
    }, []);

    const generateReferenceNumber = (): string => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        return `${day}${month}${year}-001`;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        if (!(target instanceof HTMLInputElement)) {
            console.error('Invalid event target:', target);
            return;
        }

        const { value, name, dataset } = target;
        const rowIndex = parseInt(dataset.rowIndex || '-1', 10);
        const field = name as keyof FabricOrder;

        if (isNaN(rowIndex) || rowIndex < 0) {
            console.error('Invalid row index:', rowIndex);
            return;
        }

        const newRows = [...rows];
        newRows[rowIndex] = { ...newRows[rowIndex], [field]: value } as FabricOrder;

        let validList: string[] = [];
        switch (field) {
            case 'dealerCode':
                validList = dealerCodes;
                break;
            case 'fabricCompany':
                validList = fabricCompanies.map((fc) => fc.supplierCode);
                break;
            case 'deliveryAddress':
                validList = tailorCodes;
                break;
            default:
                validList = [];
        }

        const filteredValidList = validList.filter((code) => code && typeof code === 'string');
        const matchingSuggestions = filteredValidList.filter((code) =>
            code.toLowerCase().includes(value.toLowerCase())
        );

        setSuggestions(matchingSuggestions);
        setActiveSuggestion({ rowIndex, field });

        if (newRows[rowIndex][`${field}Error` as keyof FabricOrder]) {
            newRows[rowIndex] = { ...newRows[rowIndex], [`${field}Error`]: undefined };
        }

        setRows(newRows);
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        rowIndex: number,
        field: keyof FabricOrder
    ) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            const target = e.target as HTMLInputElement;
            const currentValue = target.value;

            let validList: string[] = [];
            switch (field) {
                case 'dealerCode':
                    validList = dealerCodes;
                    break;
                case 'fabricCompany':
                    validList = fabricCompanies.map((fc) => fc.supplierCode);
                    break;
                case 'deliveryAddress':
                    validList = tailorCodes;
                    break;
                default:
                    validList = [];
            }

            const filteredValidList = validList.filter((code) => code && typeof code === 'string');
            const match = filteredValidList.find((code) =>
                code.toLowerCase().startsWith(currentValue.toLowerCase())
            );

            if (match) {
                const newRows = [...rows];
                newRows[rowIndex] = { ...newRows[rowIndex], [field]: match };
                setRows(newRows);
            }

            if (field === 'deliveryAddress' && currentValue) {
                addNewRow();
            }

            setTimeout(() => {
                const inputs = document.querySelectorAll('input');
                const currentIndex = Array.from(inputs).indexOf(target);
                if (currentIndex < inputs.length - 1) {
                    inputs[currentIndex + 1].focus();
                }
            }, 0);
        }
    };

    const handleBlur = (rowIndex: number, field: keyof FabricOrder) => {
        validateField(rowIndex, field);
    };

    const selectSuggestion = (rowIndex: number, field: keyof FabricOrder, value: string) => {
        const newRows = [...rows];
        newRows[rowIndex] = { ...newRows[rowIndex], [field]: value, [`${field}Error`]: undefined };
        setRows(newRows);
        setSuggestions([]);
        setActiveSuggestion({ rowIndex: null, field: null });
    };

    const validateField = (rowIndex: number, field: keyof FabricOrder) => {
        const newRows = [...rows];
        const value = newRows[rowIndex][field];

        let validList: string[] = [];
        switch (field) {
            case 'dealerCode':
                validList = dealerCodes;
                break;
            case 'fabricCompany':
                validList = fabricCompanies.map((fc) => fc.supplierCode);
                break;
            case 'deliveryAddress':
                validList = tailorCodes;
                break;
            default:
                validList = [];
        }

        if (value) {
            const isValid = validList.some((validValue) =>
                validValue?.toLowerCase() === value.toString().toLowerCase()
            );
            if (!isValid) {
                const errorField = `${field}Error` as keyof FabricOrder;
                newRows[rowIndex] = {
                    ...newRows[rowIndex],
                    [errorField]: `${
                        field === 'dealerCode'
                            ? 'Dealer Code'
                            : field === 'fabricCompany'
                                ? 'Fabric Company'
                                : field === 'deliveryAddress'
                                    ? 'Tailor Code'
                                    : ''
                        } Does Not Exist`,
                };
            } else {
                const properValue = validList.find(
                    (validValue) => validValue?.toLowerCase() === value.toString().toLowerCase()
                );
                newRows[rowIndex] = {
                    ...newRows[rowIndex],
                    [field]: properValue || value,
                    [`${field}Error` as keyof FabricOrder]: undefined,
                };
            }
        }

        setRows(newRows);
        setSuggestions([]);
        setActiveSuggestion({ rowIndex: null, field: null });
    };

    const addNewRow = () => {
        setRows([
            ...rows,
            {
                dealerCode: '',
                orderNumber: '',
                fabricCompany: '',
                fabricNumber: '',
                yardsRequired: '',
                metersRequired: 0,
                deliveryAddress: '',
                dealerCodeError: undefined,
                orderNumberError: undefined,
                fabricCompanyError: undefined,
                fabricNumberError: undefined,
                yardsRequiredError: undefined,
                metersRequiredError: undefined,
                deliveryAddressError: undefined,
            },
        ]);
    };

    const clearForm = () => {
        setRows([
            {
                dealerCode: '',
                orderNumber: '',
                fabricCompany: '',
                fabricNumber: '',
                yardsRequired: '',
                metersRequired: 0,
                deliveryAddress: '',
                dealerCodeError: undefined,
                orderNumberError: undefined,
                fabricCompanyError: undefined,
                fabricNumberError: undefined,
                yardsRequiredError: undefined,
                metersRequiredError: undefined,
                deliveryAddressError: undefined,
            },
        ]);
    };

    const deleteRow = (rowIndex: number) => {
        if (rows.length > 1) {
            const newRows = rows.filter((_, index) => index !== rowIndex);
            setRows(newRows);
        }
    };

    const handleSave = async () => {
        try {
            const validRows = rows.filter(
                (row) => row.dealerCode && row.orderNumber && row.fabricCompany && row.fabricNumber
            );
            if (validRows.length === 0) {
                alert('Please fill in at least one complete row');
                return;
            }

            const response = await axios.post('/api/fabric-orders/save', {
                orders: validRows,
                referenceNumber: generateReferenceNumber(),
            });

            if (response.data.success) {
                alert('Fabric orders saved successfully');
                clearForm();
            }
        } catch (error) {
            console.error('Save error:', error);
            alert('Failed to save fabric orders');
        }
    };

    const handleSaveAndPrint = async () => {
        try {
            const validRows = rows.filter(
                (row) => row.dealerCode && row.orderNumber && row.fabricCompany && row.fabricNumber
            );
            if (validRows.length === 0) {
                alert('Please fill in at least one complete row');
                return;
            }

            const response = await axios.post('/api/fabric-orders/save', {
                orders: validRows,
                referenceNumber: generateReferenceNumber(),
            });

            if (response.data.success) {
                const element = document.getElementById('pdf-content');
                if (element) {
                    const opt = {
                        margin: 1,
                        filename: `fabric-order-${generateReferenceNumber()}.pdf`,
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                    };
                    html2pdf().set(opt).from(element).save();
                }
                alert('Fabric orders saved and printed successfully');
                clearForm();
            }
        } catch (error) {
            console.error('Save and print error:', error);
            alert('Failed to save and print fabric orders');
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (tableRef.current && !tableRef.current.contains(event.target as Node)) {
                setSuggestions([]);
                setActiveSuggestion({ rowIndex: null, field: null });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div ref={tableRef}>
            <h1>MASTER TAILOR A/C "B"</h1>
            <h2>FABRIC ORDER</h2>
            <p>{new Date().toLocaleString()}</p>

            <div id="pdf-content">
                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Order No.</th>
                            <th>Fabric No.</th>
                            <th>Yardage (60")</th>
                            <th>Delivery Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{`${row.dealerCode}-${row.orderNumber}`}</td>
                                <td>{row.fabricNumber}</td>
                                <td>{row.yardsRequired}</td>
                                <td>{row.deliveryAddress}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <p>Internal Reference No. - {generateReferenceNumber()}</p>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Dealer Code</th>
                        <th>Order #</th>
                        <th>Fabric Company</th>
                        <th>Fabric Number</th>
                        <th>Yards Required</th>
                        <th>Meters Required</th>
                        <th>Delivery Address</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {/* Dealer Code */}
                            <td>
                                <input
                                    type="text"
                                    value={row.dealerCode}
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'dealerCode')}
                                    onBlur={() => handleBlur(rowIndex, 'dealerCode')}
                                    className={`w-full px-2 py-1 border rounded ${row.dealerCodeError ? 'border-red-500' : 'border-gray-300'}`}
                                    data-row-index={rowIndex}
                                    name="dealerCode"
                                />
                                {row.dealerCodeError && <span>{row.dealerCodeError}</span>}
                                {activeSuggestion.rowIndex === rowIndex &&
                                    activeSuggestion.field === 'dealerCode' &&
                                    suggestions.length > 0 && (
                                        <ul>
                                            {suggestions.map((suggestion, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => selectSuggestion(rowIndex, 'dealerCode', suggestion)}
                                                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                                                >
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                            </td>

                            {/* Order Number */}
                            <td>
                                <input
                                    type="text"
                                    value={row.orderNumber}
                                    onChange={handleInputChange}
                                    onBlur={() => handleBlur(rowIndex, 'orderNumber')}
                                    className="w-full px-2 py-1 border rounded border-gray-300"
                                    data-row-index={rowIndex}
                                    name="orderNumber"
                                />
                            </td>

                            {/* Fabric Company */}
                            <td>
                                <input
                                    type="text"
                                    value={row.fabricCompany}
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'fabricCompany')}
                                    onBlur={() => handleBlur(rowIndex, 'fabricCompany')}
                                    className={`w-full px-2 py-1 border rounded ${row.fabricCompanyError ? 'border-red-500' : 'border-gray-300'}`}
                                    data-row-index={rowIndex}
                                    name="fabricCompany"
                                />
                                {row.fabricCompanyError && <span>{row.fabricCompanyError}</span>}
                                {activeSuggestion.rowIndex === rowIndex &&
                                    activeSuggestion.field === 'fabricCompany' &&
                                    suggestions.length > 0 && (
                                        <ul>
                                            {suggestions.map((suggestion, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => selectSuggestion(rowIndex, 'fabricCompany', suggestion)}
                                                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                                                >
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                            </td>

                            {/* Fabric Number */}
                            <td>
                                <input
                                    type="text"
                                    value={row.fabricNumber}
                                    onChange={handleInputChange}
                                    className="w-full px-2 py-1 border rounded border-gray-300"
                                    data-row-index={rowIndex}
                                    name="fabricNumber"
                                />
                            </td>

                            {/* Yards Required */}
                            <td>
                                <input
                                    type="text"
                                    value={row.yardsRequired}
                                    onChange={handleInputChange}
                                    className="w-full px-2 py-1 border rounded border-gray-300"
                                    data-row-index={rowIndex}
                                    name="yardsRequired"
                                />
                            </td>

                            {/* Meters Required */}
                            <td>
                                <input
                                    type="number"
                                    value={row.metersRequired}
                                    onChange={handleInputChange}
                                    className="w-full px-2 py-1 border rounded border-gray-300"
                                    data-row-index={rowIndex}
                                    name="metersRequired"
                                />
                            </td>

                            {/* Delivery Address */}
                            <td>
                                <input
                                    type="text"
                                    value={row.deliveryAddress}
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'deliveryAddress')}
                                    onBlur={() => handleBlur(rowIndex, 'deliveryAddress')}
                                    className={`w-full px-2 py-1 border rounded ${row.deliveryAddressError ? 'border-red-500' : 'border-gray-300'}`}
                                    data-row-index={rowIndex}
                                    name="deliveryAddress"
                                />
                                {row.deliveryAddressError && <span>{row.deliveryAddressError}</span>}
                                {activeSuggestion.rowIndex === rowIndex &&
                                    activeSuggestion.field === 'deliveryAddress' &&
                                    suggestions.length > 0 && (
                                        <ul>
                                            {suggestions.map((suggestion, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => selectSuggestion(rowIndex, 'deliveryAddress', suggestion)}
                                                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                                                >
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                            </td>

                            {/* Actions */}
                            <td>
                                <button onClick={() => deleteRow(rowIndex)} className="text-red-500 hover:text-red-700">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <button onClick={addNewRow}>Add Row</button>
            <button onClick={clearForm}>Clear Form</button>
            <button onClick={handleSave}>Save</button>
            <button onClick={handleSaveAndPrint}>Save & Print</button>
        </div>
    );
};

export default OrderFabricButton;