// src/components/DealersTable.tsx

import { useState, useEffect } from 'react';
import axios from 'axios';
import type { Dealer } from '../types';

const DealersTable = () => {
  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentDealer, setCurrentDealer] = useState<Dealer | null>(null);
  const [formData, setFormData] = useState<Dealer>({
    dealerCode: '',
    name: '',
    email: '',
    contactPerson: '',
    phone: '',
    address: ''
  });

  useEffect(() => {
    fetchDealers();
  }, []);

  const fetchDealers = async () => {
    try {
      const response = await axios.get('/api/dealers'); // Match backend route
      setDealers(response.data.dealers || response.data); // Handle both formats
    } catch (error) {
      const err = error as any;
      console.error('Error fetching dealers:', err?.response?.data || err?.message);
    }
  };

  const handleEdit = (dealer: Dealer) => {
    setCurrentDealer(dealer);
    setFormData(dealer);
    setIsEditing(true);
  };

  const handleUpdate = async () => {
    try {
      if (!currentDealer) return;
      await axios.put(`/api/dealers/${currentDealer.dealerCode}`, formData); // Update dealer
      fetchDealers();
      setIsEditing(false);
      resetForm();
    } catch (error) {
      const err = error as any;
      console.error('Error updating dealer:', err?.response?.data || err?.message);
    }
  };

  const handleDelete = async (dealerCode: string) => {
    if (confirm('Are you sure you want to delete this dealer?')) {
      try {
        await axios.post('/api/dealers/delete', { dealerCode });
        fetchDealers();
      } catch (error) {
        const err = error as any;
        console.error('Error deleting dealer:', err?.response?.data || err?.message);
      }
    }
  };

  const handleAdd = async () => {
    try {
      // Basic email validation before sending
      if (!formData.email.includes('@')) {
        alert('Please enter a valid email address');
        return;
      }
      await axios.post('/api/dealers/add', formData);
      fetchDealers();
      setIsEditing(false);
      resetForm();
    } catch (error) {
      const err = error as any;
      console.error('Error adding dealer:', err?.response?.data || err?.message);
    }
  };

  const resetForm = () => {
    setFormData({
      dealerCode: '',
      name: '',
      email: '',
      contactPerson: '',
      phone: '',
      address: ''
    });
    setCurrentDealer(null);
  };

  const renderForm = () => (
    <div className="bg-white p-4 rounded shadow mb-4">
      <div className="grid grid-cols-2 gap-4">
        <input
          type="text"
          placeholder="Dealer Code"
          value={formData.dealerCode}
          onChange={(e) => setFormData({ ...formData, dealerCode: e.target.value })}
          className="p-2 border rounded"
          disabled={Boolean(currentDealer)}
        />
        <input
          type="text"
          placeholder="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="email"
          placeholder="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="text"
          placeholder="Contact Person"
          value={formData.contactPerson}
          onChange={(e) => setFormData({ ...formData, contactPerson: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="tel"
          placeholder="Phone"
          value={formData.phone}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="text"
          placeholder="Address"
          value={formData.address}
          onChange={(e) => setFormData({ ...formData, address: e.target.value })}
          className="p-2 border rounded"
        />
      </div>
      <div className="mt-4 flex justify-end gap-2">
        <button
          onClick={() => {
            setIsEditing(false);
            resetForm();
          }}
          className="px-4 py-2 bg-gray-200 rounded"
        >
          Cancel
        </button>
        <button
          onClick={currentDealer ? handleUpdate : handleAdd}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          {currentDealer ? 'Update' : 'Add'} Dealer
        </button>
      </div>
    </div>
  );

  return (
    <div>
      {!isEditing && (
        <button
          onClick={() => setIsEditing(true)}
          className="mb-4 bg-green-500 text-white px-4 py-2 rounded"
        >
          Add New Dealer
        </button>
      )}
      {isEditing && renderForm()}
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 text-left">Code</th>
            <th className="px-4 py-2 text-left">Name</th>
            <th className="px-4 py-2 text-left">Email</th>
            <th className="px-4 py-2 text-left">Contact</th>
            <th className="px-4 py-2 text-left">Phone</th>
            <th className="px-4 py-2 text-left">Address</th>
            <th className="px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {dealers.length === 0 ? (
            <tr>
              <td colSpan={7}>No dealers found</td>
            </tr>
          ) : (
            dealers.map((dealer) => (
              <tr key={dealer.dealerCode} className="border-b">
                <td className="px-4 py-2">{dealer.dealerCode}</td>
                <td className="px-4 py-2">{dealer.name}</td>
                <td className="px-4 py-2">{dealer.email}</td>
                <td className="px-4 py-2">{dealer.contactPerson}</td>
                <td className="px-4 py-2">{dealer.phone}</td>
                <td className="px-4 py-2">{dealer.address}</td>
                <td className="px-4 py-2">
                  <button
                    onClick={() => handleEdit(dealer)}
                    className="text-blue-500 hover:text-blue-700 mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(dealer.dealerCode)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DealersTable;
