// src/components/TailorsTable.tsx
import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { Tailor } from '../types/index';

// IMPORTANT: Ensure NO other variable, function, or component is declared in this file with the name 'Tailor'
// For example, make sure you haven't accidentally done something like:
// const Tailor = () => { ... };  //  <--  This would cause a conflict!

const TailorsTable: React.FC = () => {
    const [tableTailors, setTableTailors] = useState<Tailor[]>([]); // Changed to 'Tailor'
    const [isEditing, setIsEditing] = useState(false);
    const [currentTailor, setCurrentTailor] = useState<Tailor | null>(null); // Changed to 'Tailor'
    const [formData, setFormData] = useState<Tailor>({ // Changed to 'Tailor'
      tailorCode: '',
      name: '',
      deliveryAddressEnglish: '',
      deliveryAddressHKChinese: '',
      deliveryAddressCNChinese: ''
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchTailors();
  }, []);

  const fetchTailors = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/tailors');
      setTableTailors(response.data.tailors); // Using 'setTableTailors' to reflect state variable name
      setError(null);
    } catch (err) {
      const error = err as Error;
      setError('Error fetching tailors');
      console.error('Error fetching tailors:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (tailor: Tailor) => {
    setCurrentTailor(tailor);
    setFormData(tailor);
    setIsEditing(true);
  };

  const handleUpdate = async () => {
    if (!formData.tailorCode || !formData.name) {
      setError('Tailor code and name are required');
      return;
    }
    try {
      await axios.put(`/api/tailors/${currentTailor?.tailorCode}`, formData);
      fetchTailors();
      setIsEditing(false);
      resetForm();
      setError(null);
    } catch (err) {
      const error = err as Error;
      setError('Error updating tailor');
      console.error('Error updating tailor:', error);
    }
  };

  const handleDelete = async (tailorCode: string) => {
    if (confirm('Are you sure you want to delete this tailor?')) {
      try {
        await axios.post('/api/tailors/delete', { tailorCode });
        fetchTailors();
        setError(null);
      } catch (err) {
        const error = err as Error;
        setError('Error deleting tailor');
        console.error('Error deleting tailor:', error);
      }
    }
  };

  const handleAdd = async () => {
    if (!formData.tailorCode || !formData.name) {
      setError('Tailor code and name are required');
      return;
    }
    try {
      await axios.post('/api/tailors/add', formData);
      fetchTailors();
      setIsEditing(false);
      resetForm();
      setError(null);
    } catch (err) {
      const error = err as Error;
      setError('Error adding tailor');
      console.error('Error adding tailor:', error);
    }
  };

  const resetForm = () => {
    setFormData({
      tailorCode: '',
      name: '',
      deliveryAddressEnglish: '',
      deliveryAddressHKChinese: '',
      deliveryAddressCNChinese: ''
    });
    setCurrentTailor(null);
  };

  const renderForm = () => (
    <div className="bg-white p-4 rounded shadow mb-4">
      <div className="grid grid-cols-2 gap-4">
        <input
          type="text"
          placeholder="Tailor Code"
          value={formData.tailorCode}
          onChange={(e) => setFormData({ ...formData, tailorCode: e.target.value })}
          className="p-2 border rounded"
          disabled={Boolean(currentTailor)}
        />
        <input
          type="text"
          placeholder="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="text"
          placeholder="Delivery Address (English)"
          value={formData.deliveryAddressEnglish}
          onChange={(e) => setFormData({ ...formData, deliveryAddressEnglish: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="text"
          placeholder="Delivery Address (HK Chinese)"
          value={formData.deliveryAddressHKChinese}
          onChange={(e) => setFormData({ ...formData, deliveryAddressHKChinese: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="text"
          placeholder="Delivery Address (CN Chinese)"
          value={formData.deliveryAddressCNChinese}
          onChange={(e) => setFormData({ ...formData, deliveryAddressCNChinese: e.target.value })}
          className="p-2 border rounded"
        />
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      <div className="mt-4 flex justify-end gap-2">
        <button
          onClick={() => {
            setIsEditing(false);
            resetForm();
          }}
          className="px-4 py-2 bg-gray-200 rounded"
        >
          Cancel
        </button>
        <button
          onClick={currentTailor ? handleUpdate : handleAdd}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          {currentTailor ? 'Update' : 'Add'} Tailor
        </button>
      </div>
    </div>
  );

  return (
    <div>
      {!isEditing && (
        <button
          onClick={() => setIsEditing(true)}
          className="mb-4 bg-green-500 text-white px-4 py-2 rounded"
        >
          Add New Tailor
        </button>
      )}
      {isEditing && renderForm()}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-left">Tailor Code</th>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Delivery Address (English)</th>
              <th className="px-4 py-2 text-left">Delivery Address (HK Chinese)</th>
              <th className="px-4 py-2 text-left">Delivery Address (CN Chinese)</th>
              <th className="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableTailors.map((tailor) => ( // Using 'tableTailors' here to reflect state variable name
              <tr key={tailor.tailorCode} className="border-b">
                <td className="px-4 py-2">{tailor.tailorCode}</td>
                <td className="px-4 py-2">{tailor.name}</td>
                <td className="px-4 py-2">{tailor.deliveryAddressEnglish}</td>
                <td className="px-4 py-2">{tailor.deliveryAddressHKChinese}</td>
                <td className="px-4 py-2">{tailor.deliveryAddressCNChinese}</td>
                <td className="px-4 py-2">
                  <button
                    onClick={() => handleEdit(tailor)}
                    className="text-blue-500 hover:text-blue-700 mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(tailor.tailorCode)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TailorsTable;

