import { useState, useEffect } from 'react';
import axios from '../axiosConfig.ts';

interface Item {
  itemCode: string;
  description: string;
}

const ItemsTable = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<Item | null>(null);
  const [formData, setFormData] = useState<Item>({
    itemCode: '',
    description: ''
  });

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get('/api/items');
      setItems(response.data.items);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleAdd = async () => {
    try {
      await axios.post('/api/items/add', formData);
      fetchItems();
      setIsEditing(false);
      resetForm();
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleEdit = (item: Item) => {
    setCurrentItem(item);
    setFormData(item);
    setIsEditing(true);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/items/${currentItem?.itemCode}`, formData);
      fetchItems();
      setIsEditing(false);
      resetForm();
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDelete = async (itemCode: string) => {
    if (confirm('Are you sure you want to delete this item?')) {
      try {
        await axios.delete(`/api/items/${itemCode}`);
        fetchItems();
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      itemCode: '',
      description: ''
    });
    setCurrentItem(null);
  };

  return (
    <div>
      {!isEditing ? (
        <button
          onClick={() => setIsEditing(true)}
          className="mb-4 bg-green-500 text-white px-4 py-2 rounded"
        >
          Add New Item
        </button>
      ) : (
        <div className="bg-white p-4 rounded shadow mb-4">
          <div className="grid grid-cols-3 gap-4">
            <input
              type="text"
              placeholder="Item Code"
              value={formData.itemCode}
              onChange={(e) => setFormData({ ...formData, itemCode: e.target.value })}
              className="p-2 border rounded"
              disabled={Boolean(currentItem)}
            />
            <input
              type="text"
              placeholder="Description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              className="p-2 border rounded"
            />
          </div>
          <div className="mt-4 flex justify-end gap-2">
            <button
              onClick={() => {
                setIsEditing(false);
                resetForm();
              }}
              className="px-4 py-2 bg-gray-200 rounded"
            >
              Cancel
            </button>
            <button
              onClick={currentItem ? handleUpdate : handleAdd}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              {currentItem ? 'Update' : 'Add'} Item
            </button>
          </div>
        </div>
      )}

      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 text-left">Code</th>
            <th className="px-4 py-2 text-left">Description</th>
            <th className="px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.itemCode} className="border-b">
              <td className="px-4 py-2">{item.itemCode}</td>
              <td className="px-4 py-2">{item.description}</td>
              <td className="px-4 py-2">
                <button
                  onClick={() => handleEdit(item)}
                  className="text-blue-500 hover:text-blue-700 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(item.itemCode)}
                  className="text-red-500 hover:text-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ItemsTable;
