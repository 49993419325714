import axios from '../axiosConfig';

export const checkForDuplicate = async (dealerCode: string, orderNumber: string): Promise<boolean> => {
  try {
    const response = await axios.get(`/api/orders/check-duplicate/${dealerCode}/${orderNumber}`);
    return response.data.exists;
  } catch (error) {
    console.error('Error checking for duplicate:', error);
    return false;
  }
};