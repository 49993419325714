// SearchOrders.tsx
import { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Order {
 _id: string;
 dealerCode: string;
 orderNumber: string;
 customerName: string;
 item: string;
 fabricCode: string;
 createdAt: string;
 quantity: string;
 orderDate: string; // Add this
}

const SearchOrders = () => {
 const [orders, setOrders] = useState<Order[]>([]);
 const [dealers, setDealers] = useState<string[]>([]);
 const [dealerFilter, setDealerFilter] = useState('');
 const [startDate, setStartDate] = useState<Date | null>(null);
 const [endDate, setEndDate] = useState<Date | null>(null);
 const [suggestions, setSuggestions] = useState<string[]>([]);
 const [activeSuggestion, setActiveSuggestion] = useState(false);
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState('');
 const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
 const [isEditing, setIsEditing] = useState(false);
 const [editingOrder, setEditingOrder] = useState<Order | null>(null);

 useEffect(() => {
   fetchOrders();
   fetchDealers();
 }, []);

 const fetchOrders = async () => {
  setLoading(true);
  try {
    const response = await axios.get('/api/orders');
    console.log('First order quantity:', response.data.orders[0]?.quantity); // Check specific field
    console.log('Full first order:', response.data.orders[0]); // Check entire object
    setOrders(Array.isArray(response.data.orders) ? response.data.orders : []);
  } catch (error) {
    setError('Failed to fetch orders. Please try again.');
    console.error('Error fetching orders:', error);
  } finally {
    setLoading(false);
  }
};

 const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-GB', { 
    day: 'numeric', 
    month: 'short', 
    year: 'numeric' 
  });
};

 const fetchDealers = async () => {
   try {
     const response = await axios.get('/api/dealers/codes');
     setDealers(response.data.codes || []);
   } catch (error) {
     console.error('Error fetching dealers:', error);
   }
 };

 const handleEdit = (order: Order) => {
   setEditingOrder(order);
   setIsEditing(true);
 };

 const handleUpdate = async () => {
  if (!editingOrder) return;
  try {
    await axios.put(`/api/orders/${editingOrder._id}`, editingOrder);
    await fetchOrders();
    setIsEditing(false);
    setEditingOrder(null);
  } catch (error) {
    console.error('Error updating order:', error);
  }
};

 const handleSelectOrder = (orderId: string) => {
   setSelectedOrders(prev => 
     prev.includes(orderId) 
       ? prev.filter(id => id !== orderId)
       : [...prev, orderId]
   );
 };

 const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
  setSelectedOrders(e.target.checked ? filteredOrders.map(o => o._id) : []);
};




 const handleDeleteSelected = async () => {
   if (!window.confirm('Delete selected orders?')) return;
   
   try {
     await axios.post('/api/orders/delete-multiple', { orderIds: selectedOrders });
     await fetchOrders();
     setSelectedOrders([]);
   } catch (error) {
     console.error('Error deleting orders:', error);
   }
 };

 const handleDealerInputChange = (value: string) => {
   setDealerFilter(value);
   const matchingSuggestions = dealers.filter((dealer) =>
     dealer.toLowerCase().includes(value.toLowerCase())
   );
   setSuggestions(matchingSuggestions);
   setActiveSuggestion(matchingSuggestions.length > 0);
 };

 const selectDealerSuggestion = (value: string) => {
   setDealerFilter(value);
   setSuggestions([]);
   setActiveSuggestion(false);
 };

 const filteredOrders = orders.filter((order) => {
  const matchesDealer = dealerFilter ? order.dealerCode === dealerFilter : true;
  const matchesDate =
    (!startDate || new Date(order.createdAt) >= startDate) &&
    (!endDate || new Date(order.createdAt) <= endDate);
  return matchesDealer && matchesDate;
});

return (
  <div>
    <div className="flex gap-4 mb-4">
      <div className="relative">
        <input
          type="text"
          placeholder="Filter by Dealer Code"
          value={dealerFilter}
          onChange={(e) => handleDealerInputChange(e.target.value)}
          className="px-4 py-2 border rounded"
        />
        {activeSuggestion && suggestions.length > 0 && (
          <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => selectDealerSuggestion(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex gap-2">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Start Date"
          className="px-4 py-2 border rounded"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText="End Date"
          className="px-4 py-2 border rounded"
        />
      </div>
    </div>

    {selectedOrders.length > 0 && (
      <button
        onClick={handleDeleteSelected}
        className="mb-4 bg-red-500 text-white px-4 py-2 rounded"
      >
        Delete Selected ({selectedOrders.length})
      </button>
    )}

    {loading && <div className="text-center">Loading...</div>}
    {error && <div className="text-red-500 text-center">{error}</div>}

    {isEditing && editingOrder && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-4 rounded max-w-md w-full">
          <h2 className="text-xl mb-4">Edit Order</h2>
          <input
  value={editingOrder?.customerName || ''}
  onChange={(e) => setEditingOrder({...editingOrder, customerName: e.target.value})}
  className="w-full mb-2 p-2 border rounded"
  placeholder="Customer Name"
/>
<input
  value={editingOrder?.quantity || ''} // Ensure it's always a string or empty string
  onChange={(e) => setEditingOrder({...editingOrder, quantity: e.target.value})}
  className="w-full mb-2 p-2 border rounded"
  placeholder="Quantity"
/>
<input
  value={editingOrder?.item || ''}
  onChange={(e) => setEditingOrder({...editingOrder, item: e.target.value})}
  className="w-full mb-2 p-2 border rounded"
  placeholder="Item"
/>
<input
  value={editingOrder?.fabricCode || ''}
  onChange={(e) => setEditingOrder({...editingOrder, fabricCode: e.target.value})}
  className="w-full mb-2 p-2 border rounded"
  placeholder="Fabric Code"
/>
          <div className="flex justify-end gap-2 mt-4">
            <button 
              onClick={() => {
                setIsEditing(false);
                setEditingOrder(null);
              }} 
              className="px-4 py-2 bg-gray-200 rounded"
            >
              Cancel
            </button>
            <button 
              onClick={handleUpdate} 
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    )}

    <table className="w-full border-collapse">
      <thead>
        <tr className="bg-blue-500 text-white">
          <th className="px-4 py-2 border">
            <input
              type="checkbox"
              onChange={handleSelectAll}
              checked={selectedOrders.length === filteredOrders.length}
            />
          </th>
          <th className="px-4 py-2 border">Date</th> {/* Add this */}
          <th className="px-4 py-2 border">Dealer Code</th>
          <th className="px-4 py-2 border">Order Number</th>
          <th className="px-4 py-2 border">Customer Name</th>
          <th className="px-4 py-2 border">Quantity</th>
          <th className="px-4 py-2 border">Item</th>
          <th className="px-4 py-2 border">Fabric Code</th>
          <th className="px-4 py-2 border">Actions</th>
        </tr>
      </thead>
      <tbody>
        {filteredOrders.map((order) => (
          <tr key={order._id}>
            <td className="px-4 py-2 border text-center">
              <input
                type="checkbox"
                checked={selectedOrders.includes(order._id)}
                onChange={() => handleSelectOrder(order._id)}
              />
            </td>
            <td className="px-4 py-2 border">{formatDate(order.createdAt)}</td>
            <td className="px-4 py-2 border">{order.dealerCode}</td>
            <td className="px-4 py-2 border">{order.orderNumber}</td>
            <td className="px-4 py-2 border">{order.customerName}</td>
            <td className="px-4 py-2 border">{order.quantity}</td>
            <td className="px-4 py-2 border">{order.item}</td>
            <td className="px-4 py-2 border">{order.fabricCode}</td>
            <td className="px-4 py-2 border">
              <button
                onClick={() => handleEdit(order)}
                className="text-blue-500 hover:text-blue-700"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
};

export default SearchOrders;
