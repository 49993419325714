import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from '../axiosConfig';
import type { Order, Item, FabricCompany, Tailor } from '../types'; // Keep Tailor import
import { checkForDuplicate } from '../utils';

interface SuggestionState {
  rowIndex: number | null;
  field: keyof Order | null;
}

const isString = (value: unknown): value is string => typeof value === 'string';

const OrderTable = () => {
  const [dealers, setDealers] = useState<string[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [fabricCompanies, setFabricCompanies] = useState<FabricCompany[]>([]);
  const [tailors, setTailors] = useState<Tailor[]>([]); // ADDED: tailors state here
  const [rows, setRows] = useState<Order[]>([{
    id: Date.now().toString(),
    orderNumber: '',
    orderDate: '',
    itemCode: '',
    fabricCompanyCode: '',
    fabricMill: '',
    colourShade: '',
    quantity: '',
    unit: '',
    rate: 0,
    amount: 0,
    tailoringChargePerPiece: 0,
    makingCharge: 0,
    buttonsCost: 0,
    threadCost: 0,
    othersCost: 0,
    totalAmount: 0,
    remarks: '',
    dealerCode: '',
    customerName: '',
    item: '',
    fabricCompany: '',
    fabricCode: '',
    tailor: ''
  }]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [activeSuggestion, setActiveSuggestion] = useState<SuggestionState>({
    rowIndex: null,
    field: null
  });
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const [dealersResponse, itemsResponse, fabricCompaniesResponse, tailorsResponse] = await Promise.all([
            axios.get('/api/dealers/codes'),
            axios.get('/api/items'),
            axios.get('/api/fabrics/fabric-companies'),
            axios.get('/api/tailors') // Fetch tailors data
          ]);
          setDealers(dealersResponse.data.codes || []);
          setItems(itemsResponse.data.items || []);
          setFabricCompanies(fabricCompaniesResponse.data.fabricCompanies || []);
          setTailors(tailorsResponse.data.tailors || []); // Set tailors state here after fetching
        } catch (err) {
          console.error('Error fetching data:', err);
          alert('Failed to fetch data. Please try again.');
        }
      };

  const handleFieldChange = (
    rowIndex: number,
    field: keyof Order,
    value: string
  ) => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      const validationMap: Record<keyof Order, string[]> = {
        dealerCode: dealers,
        itemCode: items.map(item => item.itemCode),
        fabricCompanyCode: fabricCompanies.map(fc => fc.supplierCode),
        tailorCode: tailors.map((t: Tailor) => t.tailorCode), // Added type annotation (t: Tailor)
        orderNumber: [], orderDate: [], fabricMill: [], colourShade: [],
        quantity: [], unit: [], rate: [], amount: [], tailoringChargePerPiece: [],
        makingCharge: [], buttonsCost: [], threadCost: [], othersCost: [],
        totalAmount: [], remarks: [], customerName: [], item: [],
        fabricCompany: [], fabricCode: [], tailor: []
      };
  
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        [field]: value,
        [`${field}Error`]: undefined
      };
  
      const validList = validationMap[field] || [];
      const matchingSuggestions = validList.filter(code => 
        isString(code) && isString(value) && code.toLowerCase().includes(value.toLowerCase())
      );
  
      setSuggestions(matchingSuggestions);
      setActiveSuggestion({ rowIndex, field });
  
      return newRows;
    });
  };
  
  const validateField = (
    rowIndex: number,
    field: keyof Order
  ) => {
    const validationMap: Record<keyof Order, string[]> = {
      dealerCode: dealers,
      itemCode: items.map(item => item.itemCode),
      fabricCompanyCode: fabricCompanies.map(fc => fc.supplierCode),
      tailorCode: tailors.map((t: Tailor) => t.tailorCode), // Added type annotation (t: Tailor)
      orderNumber: [], orderDate: [], fabricMill: [], colourShade: [],
      quantity: [], unit: [], rate: [], amount: [], tailoringChargePerPiece: [],
      makingCharge: [], buttonsCost: [], threadCost: [], othersCost: [],
      totalAmount: [], remarks: [], customerName: [], item: [],
      fabricCompany: [], fabricCode: [], tailor: []
    };

    const value = rows[rowIndex][field];
    const validList = validationMap[field] || [];
    const filteredValidList = validList.filter(code => isString(code));

    setRows(prevRows => {
      const newRows = [...prevRows];
      if (isString(value)) {
        const isValid = filteredValidList.some(validValue =>
          isString(validValue) && validValue.toLowerCase() === value.toLowerCase()
        );
        if (!isValid) {
          newRows[rowIndex][`${field}Error`] = `${field === 'dealerCode' ? 'Dealer Code' :
            field === 'item' ? 'Item' :
              field === 'fabricCompany' ? 'Supplier Code' : 'Tailor Code'} Does Not Exist`;
        } else {
          const properValue = filteredValidList.find(validValue =>
            isString(validValue) && validValue.toLowerCase() === value.toLowerCase()
          );
          newRows[rowIndex][field] = properValue || value;
          newRows[rowIndex][`${field}Error`] = undefined;
        }
      }
      return newRows;
    });
    setSuggestions([]);
    setActiveSuggestion({ rowIndex: null, field: null });
  };

  const selectSuggestion = (
    rowIndex: number,
    field: keyof Order,
    value: string
  ) => {
    const newRows = [...rows];
    newRows[rowIndex][field] = value;
    newRows[rowIndex][`${field}Error`] = undefined;
    setRows(newRows);
    setSuggestions([]);
    setActiveSuggestion({ rowIndex: null, field: null });
  };

  const handleChange = (
    rowIndex: number,
    field: keyof Order,
    value: string
  ) => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        [field]: typeof field === 'string' && (field === 'quantity' || field.includes('Cost') || field === 'rate')
        ? parseFloat(value) || 0
        : value
      };
      return newRows;
    });
  };

  const deleteRow = (rowIndex: number) => {
    if (rows.length > 1) {
      const newRows = rows.filter((_, index) => index !== rowIndex);
      setRows(newRows);
    }
  };

  const saveOrder = async () => {
    try {
      const hasDuplicates = await Promise.all(
        rows.map(async (row) => {
          if (row.dealerCode && row.orderNumber) {
            return await checkForDuplicate(row.dealerCode, row.orderNumber);
          }
          return false;
        })
      );

      if (hasDuplicates.some((isDuplicate) => isDuplicate)) {
        alert('Cannot Save - Duplicate Order');
        return;
      }

      const ordersToSave = rows.filter(row =>
        row.dealerCode &&
        row.orderNumber &&
        row.customerName &&
        row.quantity &&
        row.item &&
        row.fabricCode
      ).map(({ id, ...rest }) => ({
        ...rest,
        createdAt: new Date(),
        status: 'pending'
      }));

      if (ordersToSave.length === 0) {
        alert('Please fill in all required fields');
        return;
      }

      const response = await axios.post('/api/orders/save', {
        orders: ordersToSave
      });

      if (response.data.success) {
        alert('Order saved successfully!');
        setRows([{
          id: Date.now().toString(),
          orderNumber: '',
          orderDate: '',
          itemCode: '',
          fabricCompanyCode: '',
          fabricMill: '',
          colourShade: '',
          quantity: '',
          unit: '',
          rate: 0,
          amount: 0,
          tailoringChargePerPiece: 0,
          makingCharge: 0,
          buttonsCost: 0,
          threadCost: 0,
          othersCost: 0,
          totalAmount: 0,
          remarks: '',
          dealerCode: '',
          customerName: '',
          item: '',
          fabricCompany: '',
          fabricCode: '',
          tailor: ''
        }]);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      alert('Failed to save order: ' + errorMessage);
    }
  };

  const saveAndEmail = async () => {
    try {
      const hasDuplicates = await Promise.all(
        rows.map(async (row) => {
          if (row.dealerCode && row.orderNumber) {
            return await checkForDuplicate(row.dealerCode, row.orderNumber);
          }
          return false;
        })
      );

      if (hasDuplicates.some((isDuplicate) => isDuplicate)) {
        alert('Cannot Save - Duplicate Order');
        return;
      }

      const ordersToSave = rows.filter(row =>
        row.dealerCode &&
        row.orderNumber &&
        row.customerName &&
        row.quantity &&
        row.item &&
        row.fabricCode
      ).map(({ id, ...rest }) => ({
        ...rest,
        createdAt: new Date(),
        status: 'pending'
      }));

      if (ordersToSave.length === 0) {
        alert('Please fill in all required fields');
        return;
      }

      const response = await axios.post('/api/orders/save-and-email', {
        orders: ordersToSave
      });

      if (response.data.success) {
        alert('Order saved and email sent successfully!');
        setRows([{
          id: Date.now().toString(),
          orderNumber: '',
          orderDate: '',
          itemCode: '',
          fabricCompanyCode: '',
          fabricMill: '',
          colourShade: '',
          quantity: '',
          unit: '',
          rate: 0,
          amount: 0,
          tailoringChargePerPiece: 0,
          makingCharge: 0,
          buttonsCost: 0,
          threadCost: 0,
          othersCost: 0,
          totalAmount: 0,
          remarks: '',
          dealerCode: '',
          customerName: '',
          item: '',
          fabricCompany: '',
          fabricCode: '',
          tailor: ''
        }]);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      alert('Failed to save and email order: ' + errorMessage);
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    rowIndex: number,
    field: keyof Order
  ) => {
    const target = e.target as HTMLInputElement;
    const currentValue = target.value;

    if (e.key === 'Tab') {
      e.preventDefault();

      if (field === 'dealerCode' || field === 'item' || field === 'fabricCompany' || field === 'tailor') {
        const validList =
          field === 'dealerCode' ? dealers :
            field === 'item' ? items.map(item => item.itemCode) :
              field === 'fabricCompany' ? fabricCompanies.map(fc => fc.supplierCode) :
              field === 'tailor' ? tailors.map((t: Tailor) => t.tailorCode) : []; // Added type annotation (t: Tailor)

        const filteredValidList = validList.filter(code => isString(code));
        const match = filteredValidList.find(code =>
          isString(code) && code.toLowerCase().startsWith(currentValue.toLowerCase())
        );

        if (match) {
          const newRows = [...rows];
          newRows[rowIndex][field] = match;
          setRows(newRows);
        }
        validateField(rowIndex, field);
      }

      if (!currentValue && rowIndex > 0) {
        const newRows = [...rows];
        newRows[rowIndex][field] = rows[rowIndex - 1][field];
        setRows(newRows);
      }

      if (field === 'tailor' && currentValue) {
        const newRow = {
          id: Date.now().toString(),
          orderNumber: '',
          orderDate: new Date().toISOString(),
          itemCode: '',
          fabricCompanyCode: '',
          fabricMill: '',
          colourShade: '',
          quantity: '',
          unit: '',
          rate: 0,
          amount: 0,
          tailoringChargePerPiece: 0,
          makingCharge: 0,
          buttonsCost: 0,
          threadCost: 0,
          othersCost: 0,
          totalAmount: 0,
          remarks: '',
          dealerCode: '',
          customerName: '',
          item: '',
          fabricCompany: '',
          fabricCode: '',
          tailor: ''
        };
        setRows((prevRows) => [...prevRows, newRow]);
      }

      setTimeout(() => {
        const inputs = document.querySelectorAll('input');
        const currentIndex = Array.from(inputs).indexOf(target);
        if (currentIndex < inputs.length - 1) {
          inputs[currentIndex + 1].focus();
        }
      }, 0);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tableRef.current && !tableRef.current.contains(event.target as Node)) {
        setSuggestions([]);
        setActiveSuggestion({ rowIndex: null, field: null });
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <>
      <div ref={tableRef} className="bg-white rounded-lg shadow-lg p-6">
        <table className="w-full">
          <thead>
            <tr className="border-b-2 border-gray-200">
              <th className="px-4 py-2 text-center">Dealer Code</th>
              <th className="px-4 py-2 text-center">Order #</th>
              <th className="px-4 py-2 text-center">Customer Name</th>
              <th className="px-4 py-2 text-center">Quantity</th>
              <th className="px-4 py-2 text-center">Item</th>
              <th className="px-4 py-2 text-center">Fabric Company</th>
              <th className="px-4 py-2 text-center">Fabric Code</th>
              <th className="px-4 py-2 text-center">Tailor</th>
              <th className="px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={row.id} className="border-b border-gray-100">
                {/* Dealer Code */}
                <td className="px-4 py-2 relative">
                  <input
                    type="text"
                    value={row.dealerCode}
                    onChange={(e) => handleFieldChange(rowIndex, 'dealerCode', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'dealerCode')}
                    onBlur={() => validateField(rowIndex, 'dealerCode')}
     className={`w-full px-2 py-1 border rounded ${row.dealerCodeError ? 'border-red-500' : 'border-gray-300'}`}
                  />
                  {row.dealerCodeError && <div className="text-red-500 text-sm mt-1">{row.dealerCodeError}</div>}
                  {activeSuggestion.rowIndex === rowIndex && activeSuggestion.field === 'dealerCode' && suggestions.length > 0 && (
                    <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectSuggestion(rowIndex, 'dealerCode', suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}
                </td>

                {/* Order Number */}
                <td className="px-4 py-2 relative">
                  <input
                    type="text"
                    value={row.orderNumber}
        onChange={(e) => handleChange(rowIndex, 'orderNumber', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'orderNumber')}
                    className="w-full px-2 py-1 border rounded border-gray-300"
                  />
                </td>

                {/* Customer Name */}
                <td className="px-4 py-2 w-1/5">
                  <input
                    type="text"
                    value={row.customerName}
                    onChange={(e) => handleChange(rowIndex, 'customerName', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'customerName')}
                    className="w-full px-2 py-1 border rounded border-gray-300"
                  />
                </td>

                {/* Quantity */}
                <td className="px-4 py-2 w-1/12">
                  <input
                    type="text"
                    inputMode="numeric"
                    pattern="\d*"
                    value={row.quantity}
                    onChange={(e) => handleChange(rowIndex, 'quantity', e.target.value.replace(/\D/, ''))}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'quantity')}
                    className="w-full px-2 py-1 border rounded border-gray-300"
                  />
                </td>

                {/* Item */}
                <td className="px-4 py-2 relative">
                  <input
                    type="text"
                    value={row.item}
                    onChange={(e) => handleFieldChange(rowIndex, 'item', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'item')}
                    onBlur={() => validateField(rowIndex, 'item')}
                    className={`w-full px-2 py-1 border rounded ${row.itemError ? 'border-red-500' : 'border-gray-300'}`}
                  />
                  {row.itemError && <div className="text-red-500 text-sm mt-1">{row.itemError}</div>}
                  {activeSuggestion.rowIndex === rowIndex && activeSuggestion.field === 'item' && suggestions.length > 0 && (
                    <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectSuggestion(rowIndex, 'item', suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}
                </td>

                {/* Fabric Company */}
                <td className="px-4 py-2 relative">
                  <input
                    type="text"
                    value={row.fabricCompany}
                    onChange={(e) => handleFieldChange(rowIndex, 'fabricCompany', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'fabricCompany')}
                    onBlur={() => validateField(rowIndex, 'fabricCompany')}
                    className={`w-full px-2 py-1 border rounded ${row.fabricCompanyError ? 'border-red-500' : 'border-gray-300'}`}
                  />
                  {row.fabricCompanyError && <div className="text-red-500 text-sm mt-1">{row.fabricCompanyError}</div>}
                  {activeSuggestion.rowIndex === rowIndex && activeSuggestion.field === 'fabricCompany' && suggestions.length > 0 && (
                    <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectSuggestion(rowIndex, 'fabricCompany', suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}
                </td>

                {/* Fabric Code */}
                <td className="px-4 py-2 relative">
                  <input
                    type="text"
                    value={row.fabricCode}
                    onChange={(e) => handleChange(rowIndex, 'fabricCode', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'fabricCode')}
                    className="w-full px-2 py-1 border rounded border-gray-300"
                  />
                </td>

                {/* Tailor */}
                <td className="px-4 py-2 relative">
                  <input
                    type="text"
                    value={row.tailor}
                    onChange={(e) => handleFieldChange(rowIndex, 'tailor', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'tailor')}
                    onBlur={() => validateField(rowIndex, 'tailor')}
                    className={`w-full px-2 py-1 border rounded ${row.tailorError ? 'border-red-500' : 'border-gray-300'}`}
                  />
                  {row.tailorError && <div className="text-red-500 text-sm mt-1">{row.tailorError}</div>}
                  {activeSuggestion.rowIndex === rowIndex && activeSuggestion.field === 'tailor' && suggestions.length > 0 && (
                    <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectSuggestion(rowIndex, 'tailor', suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}
                </td>

                {/* Actions */}
                <td className="px-4 py-2 relative">
                  <button
                    onClick={() => deleteRow(rowIndex)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={saveOrder}
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg"
          >
            Save
          </button>
          <button
            onClick={saveAndEmail}
            className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-lg"
          >
            Save & Email
          </button>
        </div>
      </div>
    </>
  ); // Add missing closing parenthesis
}

export default OrderTable;