import { useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import axios from '../axiosConfig';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Order {
    _id: string;
    dealerCode: string;
    orderNumber: string;
    customerName: string;
    item: string;
    fabricCode: string;
    yardage36?: string;
    yardage45?: string;
    yardage60?: string;
    createdAt: string;
}

const OrderConfirmationReport = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [dealers, setDealers] = useState<string[]>([]);
    const [dealerFilter, setDealerFilter] = useState('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [activeSuggestion, setActiveSuggestion] = useState(false);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get('/api/orders');
                setOrders(Array.isArray(response.data.orders) ? response.data.orders : [response.data.orders]);
            } catch (err) {
                const error = err as Error;
                console.error('Error fetching orders:', error);
            }
        };
        const fetchDealers = async () => {
            try {
                const response = await axios.get('/api/dealers/codes');
                setDealers(response.data.codes || []);
            } catch (err) {
                const error = err as Error;
                console.error('Error fetching dealers:', error);
            }
        };
        fetchOrders();
        fetchDealers();
    }, []);

    const handleDealerInputChange = (value: string) => {
        setDealerFilter(value);
        const matchingSuggestions = dealers.filter((dealer) =>
            dealer.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(matchingSuggestions);
        setActiveSuggestion(true);
    };

    const selectDealerSuggestion = (value: string) => {
        setDealerFilter(value);
        setSuggestions([]);
        setActiveSuggestion(false);
    };

    const filteredOrders = orders.filter((order) => {
        const matchesDealer = dealerFilter ? order.dealerCode === dealerFilter : true;
        const matchesDate =
            (!startDate || new Date(order.createdAt) >= startDate) &&
            (!endDate || new Date(order.createdAt) <= endDate);
        return matchesDealer && matchesDate;
    });


    const handleGeneratePDF = () => {
        const element = document.getElementById('report-content');
        html2pdf()
            .from(element)
            .set({
                margin: 10,
                filename: 'order_confirmation_report.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            })
            .save();
    };

    return (
        <div>
            <div className="flex gap-4 mb-4">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Filter by Dealer Code"
                        value={dealerFilter}
                        onChange={(e) => handleDealerInputChange(e.target.value)}
                        className="px-4 py-2 border rounded"
                    />
                    {activeSuggestion && suggestions.length > 0 && (
                        <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
                            {suggestions.map((suggestion, index) => (
                                <div
                                    key={index}
                                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => selectDealerSuggestion(suggestion)}
                                >
                                    {suggestion}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="flex gap-2">
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Start Date"
                        className="px-4 py-2 border rounded"
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        placeholderText="End Date"
                        className="px-4 py-2 border rounded"
                    />
                </div>
                <button
                    onClick={handleGeneratePDF}
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Download PDF
                </button>
            </div>
            <div id="report-content" className="mt-4 p-4 border">
                <h1 className="text-2xl font-bold text-center">MASTER TAILOR A/C "B"</h1>
                <h2 className="text-xl text-center">FABRIC ORDER - AT</h2>
                <p className="text-center">{new Date().toLocaleString()}</p>
                <table className="w-full mt-4 border-collapse">
                    <thead>
                        <tr className="bg-blue-500 text-white">
                            <th className="px-4 py-2 border">S. NO.</th>
                            <th className="px-4 py-2 border">ORDER. NO.</th>
                            <th className="px-4 py-2 border">FABRIC NO.</th>
                            <th className="px-4 py-2 border">YARDAGE (36)</th>
                            <th className="px-4 py-2 border">YARDAGE (45)</th>
                            <th className="px-4 py-2 border">YARDAGE (60)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.map((order, index) => (
                            <tr key={order._id || order.orderNumber}>
                                <td className="px-4 py-2 border text-center">{index + 1}</td>
                                <td className="px-4 py-2 border text-center">{order.orderNumber}</td>
                                <td className="px-4 py-2 border text-center">{order.fabricCode}</td>
                                <td className="px-4 py-2 border text-center">{order.yardage36 || '0'}</td>
                                <td className="px-4 py-2 border text-center">{order.yardage45 || '0'}</td>
                                <td className="px-4 py-2 border text-center">{order.yardage60 || '0'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p className="mt-4 text-sm">
                    * Please deliver the above mentioned orders to our office as soon as possible.
                </p>
                <p className="text-sm">
                    * If there is any discrepancy in the price code please inform us.
                </p>
                <p className="mt-4 text-right text-sm">
                    <strong>Internal Reference No. - {new Date().toISOString().slice(0, 10).replace(/-/g, '')}</strong>
                </p>
                <p className="text-right text-sm">Page 1 of 1</p>
            </div>
        </div>
    );
};

export default OrderConfirmationReport;