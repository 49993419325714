import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css'; // Import global styles
import App from './App'; // Import the main App component
import './axiosConfig'; // Import Axios configuration (if needed)

// Get the root element from the DOM
const rootElement = document.getElementById('root');

// Ensure the root element exists before rendering
if (rootElement) {
  const root = createRoot(rootElement); // Create a root for React rendering
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Root element with ID 'root' not found in the DOM.");
}