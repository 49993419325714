
import { useState, useEffect } from 'react';
import axios from 'axios';
import type { FabricCompany } from '../types';

const FabricSuppliersTable = () => {
  const [fabricCompanies, setFabricCompanies] = useState<FabricCompany[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentFabricCompany, setCurrentFabricCompany] = useState<FabricCompany | null>(null);
  const [formData, setFormData] = useState<FabricCompany>({
    supplierCode: '',
    name: '',
    whatsappLink: '',
    email: '',
    measurementUnit: 'Yards' // Default value
  });

  useEffect(() => {
    fetchFabricCompanies();
  }, []);

  const fetchFabricCompanies = async () => {
    try {
      const response = await axios.get('/api/fabrics/fabric-companies');
      setFabricCompanies(response.data.fabricCompanies || response.data); // Handle both formats
    } catch (error) {
      const err = error as any;
      console.error('Error fetching fabric companies:', err?.response?.data || err?.message);
    }
  };

  const handleEdit = (fabricCompany: FabricCompany) => {
    setCurrentFabricCompany(fabricCompany);
    setFormData(fabricCompany);
    setIsEditing(true);
  };

  const resetForm = () => {
    setFormData({
      supplierCode: '',
      name: '',
      whatsappLink: '',
      email: '',
      measurementUnit: 'Yards'
    });
    setCurrentFabricCompany(null);
  };

  const handleUpdate = async () => {
    try {
      if (!currentFabricCompany) return;
      await axios.put(`/api/fabrics/fabric-companies/${currentFabricCompany.supplierCode}`, formData);
      fetchFabricCompanies();
      setIsEditing(false);
      resetForm();
    } catch (error) {
      const err = error as any;
      console.error('Error updating fabric company:', err?.response?.data || err?.message);
    }
  };

  const handleDelete = async (supplierCode: string) => {
    if (confirm('Are you sure you want to delete this fabric company?')) {
      try {
        await axios.post('/api/fabrics/fabric-companies/delete', { supplierCode });
        fetchFabricCompanies();
      } catch (error) {
        const err = error as any;
        console.error('Error deleting fabric company:', err?.response?.data || err?.message);
      }
    }
  };

  const handleAdd = async () => {
    try {
      await axios.post('/api/fabrics/fabric-companies/add', formData);
      fetchFabricCompanies();
      setIsEditing(false);
      resetForm();
    } catch (error) {
      const err = error as any;
      console.error('Error adding fabric company:', err?.response?.data || err?.message);
    }
  };

  const renderForm = () => (
    <div className="bg-white p-4 rounded shadow mb-4">
      <div className="grid grid-cols-2 gap-4">
        <input
          type="text"
          placeholder="Supplier Code"
          value={formData.supplierCode}
          onChange={(e) => setFormData({ ...formData, supplierCode: e.target.value })}
          className="p-2 border rounded"
          disabled={Boolean(currentFabricCompany)}
        />
        <input
          type="text"
          placeholder="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="text"
          placeholder="WhatsApp Link"
          value={formData.whatsappLink}
          onChange={(e) => setFormData({ ...formData, whatsappLink: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="email"
          placeholder="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="p-2 border rounded"
        />
        <select
          value={formData.measurementUnit}
          onChange={(e) => setFormData({ ...formData, measurementUnit: e.target.value as 'Yards' | 'Meters' })}
          className="p-2 border rounded"
        >
          <option value="Yards">Yards</option>
          <option value="Meters">Meters</option>
        </select>
      </div>
      <div className="mt-4 flex justify-end gap-2">
        <button
          onClick={() => {
            setIsEditing(false);
            resetForm();
          }}
          className="px-4 py-2 bg-gray-200 rounded"
        >
          Cancel
        </button>
        <button
          onClick={currentFabricCompany ? handleUpdate : handleAdd}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          {currentFabricCompany ? 'Update' : 'Add'} Fabric Company
        </button>
      </div>
    </div>
  );

  return (
    <div>
      {!isEditing && (
        <button
          onClick={() => setIsEditing(true)}
          className="mb-4 bg-green-500 text-white px-4 py-2 rounded"
        >
          Add New Fabric Company
        </button>
      )}
      {isEditing && renderForm()}
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 text-left">Supplier Code</th>
            <th className="px-4 py-2 text-left">Name</th>
            <th className="px-4 py-2 text-left">WhatsApp Link</th>
            <th className="px-4 py-2 text-left">Email</th>
            <th className="px-4 py-2 text-left">Measurement Unit</th>
            <th className="px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {fabricCompanies.length === 0 ? (
            <tr><td colSpan={6}>No fabric companies found</td></tr>
          ) : (
            fabricCompanies.map((fabricCompany) => (
              <tr key={fabricCompany.supplierCode} className="border-b">
                <td className="px-4 py-2">{fabricCompany.supplierCode}</td>
                <td className="px-4 py-2">{fabricCompany.name}</td>
                <td className="px-4 py-2">{fabricCompany.whatsappLink}</td>
                <td className="px-4 py-2">{fabricCompany.email}</td>
                <td className="px-4 py-2">{fabricCompany.measurementUnit}</td>
                <td className="px-4 py-2">
                  <button
                    onClick={() => handleEdit(fabricCompany)}
                    className="text-blue-500 hover:text-blue-700 mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(fabricCompany.supplierCode)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FabricSuppliersTable;
