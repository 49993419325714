import { useState } from 'react';
import OrderTable from './components/OrderTable';
import SearchOrders from './components/SearchOrders';
import DealersTable from './components/DealersTable';
import ItemsTable from './components/ItemsTable';
import OrderFabricButton from './components/OrderFabricButton';
import FabricSuppliersTable from './components/FabricSuppliersTable';
import TailorsTable from './components/TailorsTable';
import OrderConfirmationReport from './components/OrderConfirmationReport';

function App() {
    const [activeTab, setActiveTab] = useState('newOrder');
    const [showReports, setShowReports] = useState(false);
    const [error] = useState<Error | null>(null); // Fix error handling types

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        if (tab !== 'reports') setShowReports(false);
    };

    return (
        <div className="container mx-auto p-4">
            <nav className="flex gap-2 mb-4">
                <button
                    onClick={() => handleTabClick('newOrder')}
                    className={`px-4 py-2 rounded ${activeTab === 'newOrder' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Add New Order
                </button>
                <button
                    onClick={() => handleTabClick('searchOrders')}
                    className={`px-4 py-2 rounded ${activeTab === 'searchOrders' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Search Orders
                </button>
                <button
                    onClick={() => handleTabClick('orderFabric')}
                    className={`px-4 py-2 rounded ${activeTab === 'orderFabric' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Order Fabric
                </button>
                <button
                    onClick={() => handleTabClick('dealers')}
                    className={`px-4 py-2 rounded ${activeTab === 'dealers' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Dealers
                </button>
                <button
                    onClick={() => handleTabClick('items')}
                    className={`px-4 py-2 rounded ${activeTab === 'items' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Items
                </button>
                <button
                    onClick={() => handleTabClick('fabricSuppliers')}
                    className={`px-4 py-2 rounded ${activeTab === 'fabricSuppliers' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Fabric Suppliers
                </button>
                <button
                    onClick={() => handleTabClick('tailors')}
                    className={`px-4 py-2 rounded ${activeTab === 'tailors' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Tailors
                </button>
                <button
                    onClick={() => {
                        setActiveTab('reports');
                        setShowReports(!showReports);
                    }}
                    className={`px-4 py-2 rounded ${activeTab === 'reports' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Reports
                </button>
            </nav>

            {showReports && (
                <div className="flex gap-2 mb-4">
                    <button
                        onClick={() => handleTabClick('orderConfirmationReport')}
                        className="px-4 py-2 bg-green-500 text-white rounded"
                    >
                        Order Confirmation Report
                    </button>
                    <button
                        onClick={() => handleTabClick('orderFabricReport')}
                        className="px-4 py-2 bg-green-500 text-white rounded"
                    >
                        Order Fabric Report
                    </button>
                </div>
            )}

            {error && ( // Example error display
                <div className="text-red-500 mb-4">
                    Error: {error.message}
                </div>
            )}

            {activeTab === 'newOrder' && <OrderTable />}
            {activeTab === 'searchOrders' && <SearchOrders />}
            {activeTab === 'orderFabric' && <OrderFabricButton />}
            {activeTab === 'dealers' && <DealersTable />}
            {activeTab === 'items' && <ItemsTable />}
            {activeTab === 'tailors' && <TailorsTable />}
            {activeTab === 'fabricSuppliers' && <FabricSuppliersTable />}
            {activeTab === 'orderConfirmationReport' && <OrderConfirmationReport />}
            {activeTab === 'orderFabricReport' && <div>Order Fabric Report Content</div>}

        </div>
    );
}

export default App;
